<template>
  <component :is="ComponentToRender" :="props">
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" :="slotData" />
    </template>
  </component>
</template>

<script setup lang="ts">
import type { CardData } from '~/types'

const props = defineProps<
  CardData & {
    classes?: {
      image?: string
      heading?: string
      summary?: string
    }
  }
>()

const ComponentToRender = computed(() => {
  if (props.variation === 'image') return resolveComponent('LazyCardImage')
  if (props.variation === 'promotion')
    return resolveComponent('LazyCardPromotion')
  return resolveComponent('LazyCardBasic')
})
</script>
